import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["summaryCardsImage", "generateSummaryButton", "generatingSummaryButton"]

  toggleLoadingState() {
    this.generateSummaryButtonTarget.classList.add("hidden");
    this.generatingSummaryButtonTarget.classList.remove("hidden");

    this.summaryCardsImageTarget.classList.remove("bg-new-ai-summary-static");
    this.summaryCardsImageTarget.classList.add("bg-new-ai-summary-animated");
  }
}
